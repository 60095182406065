.table-row {
  &__status {
  }

  &__cell {
    font-size: 16px;
    text-align: center;

    &_short {
      width: 25%;
    }
    &_active {
      cursor: pointer;
    }
  }
}
