@use "sass:color";

.admin-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  width: 70%;

  margin: 20px auto 0;

  &__head {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__button {
    background-color: #00984d;
    border: none;

    &:hover {
      background-color: color.adjust(#00984d, $blackness: 4%);
    }
  }
}
