.project-head {
  display: flex;
  justify-content: flex-end;

  width: 100%;
  padding: 10px;
  border-radius: 14px;

  background: #b3a0a04f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &__button {
    width: 100%;
  }
}
