.admin-records {
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 100%;

  margin-top: 20px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 20px;
  }

  &__item-title {
    font-size: 16px;
    font-weight: 700;
  }

  &__item-text {
    opacity: 0.4;
  }
}

.admin-answers {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__title {
    font-size: 18px;
    line-height: normal;
    font-weight: 700;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__question {
    font-size: 16px;
    line-height: normal;
    font-weight: 600;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 20px;
  }

  &__item-title {
    font-size: 14px;
    line-height: normal;
    font-weight: 500;
  }

  &__item-description {
    font-size: 14px;
    line-height: normal;
    font-weight: 400;

    &_dim {
      opacity: 0.4;
    }
  }
}
