.expert-project {
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  max-width: 1200px;
  flex: 0 0 auto;

  padding: 24px;
  margin: 0 0 100px;

  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  &__title {
    font-weight: 700;
  }

  &__text-regular {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
}
