@use "sass:color";

.expert-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 15px;

  width: 100%;
  max-width: 700px;

  padding-bottom: 100px;

  &__button {
    margin-right: auto;
  }
}

.form-card {
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 100%;
  padding: 24px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  &__title {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
  }

  &__text {
    font-size: 14px;
    line-height: normal;
    margin: 0;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__info-item {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__radio {
    display: flex;
    align-items: center;
    gap: 10px;

    padding: 5px 10px;

    cursor: pointer;
    background-color: white;
    border-radius: 12px;

    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: color.adjust(white, $blackness: 5%);
    }
  }

  &__radio-input {
    width: 15px;
    height: 15px;
  }
}
