.expert-auth {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 15%;

  gap: 50px;

  &__logo {
    width: 65px;
    height: 65px;
  }

  &__logo-text {
    width: 124px;
    height: 34px;
  }

  &__form {
    display: flex;
    flex-direction: column;

    gap: 50px;
  }

  &__field {
    border-radius: 20px;
  }

  &__error {
    color: red;
    font-size: 14px;
  }
}
