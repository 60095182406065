@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');

.global{
    font-family: 'Montserrat', sans-serif;
}

.button{
    border-radius: 20px;
    width: 160px;
    background: #00984D;
    font-weight: 700;
    font-size: 16px;
    outline: none;
    border:none;
}

.button:hover{
    background: #006934;
    outline: none;
}

.button:focus{
    background: #006934;
    box-shadow: none !important;
}

.button:disabled{
    background: gray;
    box-shadow: none !important;
}

.input{
    width: 500px;
}

.side-nav{
    max-height: 250px;
    width: 272px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.side-nav label{
    margin: 12px 0 0;
    width: 260px;
    background: #FFFFFF;
    padding: 12px;
    border-radius: 24px;
}

.side-nav input{
    visibility: hidden;
}

.side-nav label:hover{
    background: #F7F7F7;
}

.item-active{
    background: #F7F7F7;
    color:#00984D;
    font-weight: 700;
}

.create-page{
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 50px;
    width: 40rem;
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    margin-bottom: 200px;
}

.admin-page{
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 50px;
    width: 40rem;
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    margin-bottom: 200px;
}

.file-input{
    color:black;
    background: #F7F7F7;
    border: 1px solid #C4C4C4;
    border-radius: 24px;
    width: 247px;
    text-align: center;
    padding: 6px 32px 6px 32px;
}

.file-input:focus{
    color:black;
    background: #F7F7F7;
    border: 1px solid #C4C4C4;
    border-radius: 24px;
    width: 247px;
    text-align: center;
    padding: 6px 32px 6px 32px;
}

.file-input:hover{
    color:black;
    background: #F7F7F7;
    border: 1px solid #C4C4C4;
    border-radius: 24px;
    width: 247px;
    text-align: center;
    padding: 6px 32px 6px 32px;
}





.error{
    font-size: 12px;
    color:red;
    opacity: 1;
    margin: 0;
}

.error-hidden{
    font-size: 12px;
    color:red;
    opacity: 0;
    margin: 0;
}

/*newnewnew*/

.main{
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    align-items: center;

}
.main h1{
    font-weight: 700;
    font-size: 48px;
    text-align: center;
}

.main h5{
    margin-top: 48px;
    font-weight: 300;
    font-size: 24px;
    text-align: center;
    max-width: 926px;
}

.main p{
    margin-top: 24px;
    text-align: center;
    font-size: 16px;
}

.main p span{
    margin-top: 24px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
}

.modal-span{
    margin-left: 10px;
    font-size: 16px;
    color: grey;
}

.download-span{
    font-size: 16px;
    text-decoration: underline;
    color: blue;
}

.download-span:hover{
    cursor: pointer;
}

.button-big{
    border-radius: 20px;
    background: #00984D;
    font-weight: 700;
    padding-left: 80px;
    padding-right: 80px;
    font-size: 24px;
    outline: none;
    border:none;
}

.button-big:hover{
    background: #006934;
    outline: none;
}

.button-big:focus{
    background: #006934;
    box-shadow: none !important;
}
.header{
    display: flex;
    align-items: center;
    margin: 12px 70px 0;
    justify-content: space-between;
}
.header p{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    margin: 5px 0 0 55px;
    width:150px;
}

.header-logo{
    height: 60px;
    width:  100px;
}

.header-logo-text{
    height: 25px;
    width:  100px;
}

.exit-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    width: 120px;
    background: transparent;
    color:black;
    font-weight: 500;
    font-size: 14px;
    outline: none;
    border:none;
}

.exit-button:hover{
    background: transparent;
    color:black;
    outline: none;
}

.exit-button:focus{
    background: transparent;
    color:black;
    box-shadow: none !important;
}

.exit-button img{
    margin-left: 5px;
    width: 20px;
    height: 20px;
}

.table-own{
    margin-top: 48px;
    min-width: 725px;
}

.table-header{
    font-size: 16px;
}

.table-td{
    font-size: 16px;
    text-align: center;
}

.table-td-active{
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

.grants{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.grants h2{
    margin-top: 70px;
}

.grant-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    width: 232px;
    height: 330px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    flex: none;
    order: 3;
    flex-grow: 0;
    margin: 0px 24px;
}

.grant-item img{
    background: green;
    width: 64px;
    height: 64px;
    border-radius: 50%;
}

.grant-item h3{
    font-size: 24px;
    text-align: center;
    font-weight: 700;
}

.back-button{
    position: absolute;
    top: 70px;
    left: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    width:180px;
    background: transparent;
    color:black;
    font-weight: 500;
    font-size: 14px;
    outline: none;
    border:none;
}

.back-button:hover{
    background: transparent;
    color:black;
    outline: none;
}

.back-button:focus{
    background: transparent;
    color:black;
    box-shadow: none !important;
}

.create-grant{
    display: flex;
    flex-direction: column;
    margin-top: 70px;
}

.create-grant h2{
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}

.create-grant p{
    font-size: 16px;
    text-align: center;
}

.input-modal{

}

.data-set-name{
    font-weight: 700;
}

.data-show{

}

.data-show h5{
    font-weight: 700;
}

.data-show h6{
    font-size: 18px;
}

.data-show p{
    font-size: 16px;
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
#sidebar-wrapper{
    min-height: 100vh !important;
    width: 100vw;
    margin-left: -1rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
}

.mobile-sidebar{
    position: absolute;
    top: 60px;
    left: 0;
    background: white;
    width: 100%;
    height: 100%;
    z-index: 30;
}
.menu-button{
    font-size: 14px;
    position: absolute;
    outline: none;
    background: green!important;
    color:white !important;
    height: 30px;
    width: 92% !important;
}

.menu-button:hover{
    position: absolute;
    outline: none;
    left: 0;
    width: 90%;
}

.menu-button:active{
    position: absolute;
    outline: none;
}

.menu-button:focus{
    position: absolute;
    outline: none;
}

@media only screen and (max-width: 700px) {
    .main{
        display: flex;
        flex-direction: column;
        margin-top: 10%;
        align-items: center;
    }

    .table-header{
        font-size: 10px;
    }

    .table-td{
        font-size: 10px;
        text-align: center;
    }

    .back-button{
        position: absolute;
        top: 60px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 20px;
        width:140px;
        background: transparent;
        color:black;
        font-weight: 500;
        font-size: 10px;
        outline: none;
        border:none;
    }

    .back-button img{
        width: 5px;
    }

    .back-button:hover{
        background: transparent;
        color:black;
        outline: none;
    }

    .back-button:focus{
        background: transparent;
        color:black;
        box-shadow: none !important;
    }

    .table-td-active{
        font-size: 10px;
        text-align: center;
        cursor: pointer;
    }

    .grants h2{
        margin-top: 40px;
    }

    .grant-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 10px 6px 15px;
        width: 150px;
        height: 250px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 24px;
        flex: none;
        order: 3;
        flex-grow: 0;
        margin: 0px 5px;
    }

    .grant-item h3{
        font-size: 18px;
        text-align: center;
        font-weight: 700;
    }

    .create-grant{
        display: flex;
        flex-direction: column;
        margin-top: 50px;
    }

    .create-grant h2{
        text-align: center;
        font-size: 16px;
        font-weight: bold;
    }

    .create-grant p{
        font-size: 12px;
        text-align: center;
    }

    .main h1{
        font-weight: 700;
        font-size: 24px;
        text-align: center;
    }
    .main h5{
        font-size: 16px;
        text-align: center;
    }

    .main p{
        text-align: center;
        font-size: 12px;
    }

    .main p span{
        margin-top: 24px;
        text-align: center;
        font-size: 12px;
        cursor: pointer;
    }

    .modal-span{
        margin-left: 10px;
        font-size: 12px;
        color: grey;
    }

    .download-span{
        font-size: 12px;
        text-decoration: underline;
        color: blue;
    }

    .download-span:hover{
        cursor: pointer;
    }

    .table-own{
        margin-top: 20px;
        min-width: 320px;
    }

    .button-big{
        border-radius: 20px;
        background: #00984D;
        font-weight: 700;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 14px;
        outline: none;
        border:none;
    }

    .button-big:hover{
        background: #006934;
        outline: none;
    }

    .header{
        display: flex;
        align-items: center;
        margin: 12px 10px 0;
        justify-content: space-between;
    }

    .header p{
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        margin: 2px 0 0 15px;
        width:150px;
    }

    .button-big:focus{
        background: #006934;
        box-shadow: none !important;
    }

    .header-logo{
        height: 40px;
        width: 40px;
    }
    .header-logo-text{
        height: 16px;
        width: 64px;
    }

    .button{
        border-radius: 20px;
        width: 100px;
        background: #00984D;
        font-weight: 700;
        font-size: 12px;
        outline: none;
        border:none;
    }

    .button:hover{
        background: #006934;
        outline: none;
    }

    .button:focus{
        background: #006934;
        box-shadow: none !important;
    }

    .button:disabled{
        background: gray;
        box-shadow: none !important;
    }

    .exit-button{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: transparent;
        color:black;
        width: 70px;
        font-weight: 500;
        font-size: 10px;
        outline: none;
        border:none;
        margin-right: 5px;
    }

    .exit-button img{
        margin-left: 5px;
        width: 12px;
        height: 12px;
    }

    .exit-button:hover{
        background: transparent;
        color:black;
        outline: none;
    }

    .exit-button:focus{
        background: transparent;
        color:black;
        box-shadow: none !important;
    }

    .create-page{
        margin:40px 0 50px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
        border-radius: 24px;
        width: 100%;
    }

    .input-label{
        font-size: 12px;
        margin-top: 20px;
        margin-left: 20px;
    }

    .data-set-name{
        margin-left: 10px;
        font-weight: 700;
        font-size: 16px;
    }

    .input-modal{
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .file-input{
        color:black;
        background: #F7F7F7;
        border: 1px solid #C4C4C4;
        border-radius: 24px;
        width: 187px;
        margin-left: 10px;
        font-size: 12px;
        text-align: center;
        padding: 6px 32px 6px 32px;
    }

    .file-input:focus{
        color:black;
        background: #F7F7F7;
        border: 1px solid #C4C4C4;
        border-radius: 24px;
        width: 187px;
        text-align: center;
        padding: 6px 32px 6px 32px;
    }

    .file-input:hover{
        color:black;
        background: #F7F7F7;
        border: 1px solid #C4C4C4;
        border-radius: 24px;
        width: 187px;
        text-align: center;
        padding: 6px 32px 6px 32px;
    }

    .menu-button{
        position: absolute;
        outline: none!important;
        border: none!important;
        left: 20px!important;
        display: flex;
        font-size: 12px;
        justify-content: space-between;
        color:black;
        top:60px;
        background: none;
        width: 90%;
    }

    .menu-button:hover{
        position: absolute;
        outline: none!important;
        border: none!important;
        left: 0;
        justify-content: space-between;
        display: flex;
        color:black;
        top:60px;
        background: none;
        width: 90%;
    }

    .menu-button:active{
        position: absolute;
        outline: none!important;
        border: none!important;
        left: 0;
        justify-content: space-between;
        display: flex;
        color:black;
        top:60px;
        background: none;
        width: 90%;
    }

    .menu-button:focus{
        position: absolute;
        outline: none!important;
        border: none;
        left: 0;
        display: flex;
        color:black;
        top:60px;
        background: none;
        width: 90%;
        box-shadow: none !important;
    }

    .side-nav{
        max-height: 250px;
        background: #FFFFFF;
        box-shadow: none;
        border-radius: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .side-nav label{
        margin: 12px 0 0;
        width: 260px;
        background: #FFFFFF;
        padding: 12px;
        border-radius: 0;
    }

    .side-nav label:focus{

        background: none;

    }

    .side-nav input{
        visibility: hidden;
    }

    .side-nav label:hover{
        background: #F7F7F7;
    }

    .item-active{
        font-size: 12px;
    }

    .item-nonactive{
        font-size: 12px;
    }

    .data-show{

    }

    .data-show h5{
        font-size: 16px;
        font-weight: 700;
    }

    .data-show h6{
        font-size: 14px;
    }

    .data-show p{
        font-size: 12px;
    }
}



