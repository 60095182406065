.form-answers {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__title {
    font-size: 20px;
    font-weight: 800;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__item-title {
    font-size: 16px;
    font-weight: 600;
  }

  &__item-value {
    font-size: 14px;
    font-weight: 400;
  }
}
