.expert-cabinet {
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding-top: 20px;

  width: 100%;
  max-width: 1200px;

  height: 100%;
}
