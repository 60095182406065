.expert-grant {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 30px;

  width: 100%;
  max-width: 600px;
}
