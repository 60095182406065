.expert-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-top: 40px;
  max-width: 600px;
  padding: 20px;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    width: 100%;
  }
}
