.create-account {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__block {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
